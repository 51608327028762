
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexhQ73h2JqYqMeta } from "/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/[enterpriseId]/index.vue?macro=true";
import { default as usersWdvAIuLvItMeta } from "/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/[enterpriseId]/users.vue?macro=true";
import { default as _91enterpriseId_93u4JBhlVIKCMeta } from "/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/[enterpriseId].vue?macro=true";
import { default as index2eL95qMRzVMeta } from "/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/index.vue?macro=true";
import { default as enterprises9hIYadYbmZMeta } from "/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises.vue?macro=true";
import { default as indexGivc9shDCKMeta } from "/home/bgrand/Documents/Projects/live-display/admin/pages/index.vue?macro=true";
export default [
  {
    name: enterprises9hIYadYbmZMeta?.name,
    path: "/enterprises",
    component: () => import("/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises.vue"),
    children: [
  {
    name: _91enterpriseId_93u4JBhlVIKCMeta?.name,
    path: ":enterpriseId()",
    component: () => import("/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/[enterpriseId].vue"),
    children: [
  {
    name: "enterprises-enterpriseId",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/[enterpriseId]/index.vue")
  },
  {
    name: "enterprises-enterpriseId-users",
    path: "users",
    component: () => import("/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/[enterpriseId]/users.vue")
  }
]
  },
  {
    name: "enterprises",
    path: "",
    component: () => import("/home/bgrand/Documents/Projects/live-display/admin/pages/enterprises/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexGivc9shDCKMeta || {},
    redirect: "/enterprises",
    component: () => import("/home/bgrand/Documents/Projects/live-display/admin/pages/index.vue")
  }
]